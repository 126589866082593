<template>
    <v-container>
        <v-row>
            <v-col cols="8" offset="2">

                <v-card>
                    <v-card-title>{{ $t('terms') }}</v-card-title>

                    <v-card-text>
                        {{ $t('please_download') }}
                        <a class="textlink" :href="termsString">{{ $t('terms') }}</a>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: "Terms",

        computed: {
            ...mapState(['base']),

            termsString() {
                return this.base.url + "/assets/terms.zip"
            },
        },

    }
</script>

<style scoped>
.textlink {
    text-decoration: none;
}
</style>
